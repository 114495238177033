<template>
  <div>
    <el-row>
      <el-col :span="24">
        <div class="titmsg">填写并核对订单信息</div>
        <el-col :span="24">
          <div class="titBox">
            <TitleHeader msg="收货信息"></TitleHeader>
          </div>
          <DeliveryInfo v-if="isReady" :goodsList="orderList"></DeliveryInfo>
        </el-col>
       
        <el-col :span="24">
            <div class="notitBox">
          <TitleHeader msg="商品清单" :noBorder="true"></TitleHeader>
            </div>
          <IntegralMallOrderList
            v-if="isReady"
            :goodsList="orderList"
          ></IntegralMallOrderList>
        </el-col>
      </el-col>
    </el-row>
    <IntegralMallOrderBottom v-if="isReady" :goodsList="orderList"></IntegralMallOrderBottom>
  </div>
</template>

<script>
const TitleHeader = () => import("components/common/TitleHeader.vue");
const DeliveryInfo = () => import("components/order/DeliveryInfo.vue");
const IntegralMallOrderList = () => import("components/order/IntegralMallOrderList.vue");
const IntegralMallOrderBottom = () => import("components/order/IntegralMallOrderBottom.vue");
import { IntegralMallOrder } from "api/activity.js";
import {freightList} from "api/order.js";
export default {
  name: "GoodsAmount",
  data() {
    return {
      isReady:false,
      moreIds: "", //购物车商品ids
      orderList: "",
      value: "",
    };
  },
 
  components: {
    TitleHeader,
    DeliveryInfo,
    IntegralMallOrderList,
    IntegralMallOrderBottom,
  },
  methods: {
    payableAmountFun(){
       let  couponAmountNum=0;    //订单优惠券
      if(this.orderList.couponAmount>0){
          couponAmountNum=Number(this.orderList.couponAmount)
      }
      let originalAmount=Number(this.orderList.originalAmount)    //商品金额

      let couponDetailAmount=Number(this.orderList.couponDetailAmount) //单品优惠券
      let promotionAmount=Number(this.orderList.promotionAmount)   //促销折扣

      // 除运费之后的价格
      let  totalPrice=originalAmount-couponDetailAmount-promotionAmount-couponAmountNum
      if(totalPrice<0){
        totalPrice= Number(this.orderList.goodsCount) * 0.01
      }
      let freight=Number(this.orderList.freight)     //运费

      // alert(this.orderList.orderType!=4 && this.orderList.orderType!=5)
      if(totalPrice>0){

        // 如果非a+b活动订单  请求接口查运费区间，根据运费区间得到运费价格
        if(this.orderList.orderType!=4 && this.orderList.orderType!=5){
          freightList().then(res=>{
              let records=res.data.data.records;
              records.forEach(item => {
                if(totalPrice>item.startMoney && totalPrice<item.endMoney){
                    freight=Number(item.freight)     //运费
                    // 应付金额
                    let  payableAmount=originalAmount+freight-couponDetailAmount-promotionAmount-couponAmountNum
                    this.orderList.payableAmount=payableAmount.toFixed(2);
                    this.orderList.freight=Number(item.freight)
                    //  alert('aaaaa'+ this.orderList.freight)
                    return false
                }
              });
            })
        }else{
          let  payableAmount=originalAmount+freight-couponDetailAmount-promotionAmount-couponAmountNum
          this.orderList.payableAmount=payableAmount.toFixed(2);
          this.orderList.freight=Number(freight) 
        }
      }
      // let freight=Number(this.orderList.freight)     //运费
    
     
    },

    // 通过data渲染数据
    RenderData(data){
          this.orderList = data.data.data;
          this.isReady=true;
          // 关闭loading
          this.$loading().close();
    }


  },
  created() {
     // 显示loading

      this.$loading({ fullscreen: true ,background:'#ffffff45'})
            // alert(this.$route.query.id)
            let datas={
              id:this.$route.query.id
            }
            IntegralMallOrder(datas).then(data=>{
               if(data.data.code==400){
                    this.$message({
                      message: data.data.msg,
                      type: "warning",
                    });
                  window.history.back()
                }
                this.RenderData(data)
            })
  },
};
</script>


<style lang="less" scoped>
@import "~style/index.less";
.titBox{
  padding: 20px;
  border: 1px solid #f0f0f0;
  border-bottom: none;
}
.notitBox{
   padding: 20px;
}
.titmsg {
  font-size: 16px;
  color: #333;
  line-height: 62px;
  font-weight: bold;
}
.invoiceItem {
  padding: 0 20px 20px 20px;
  border: 1px solid #f0f0f0;
  border-top: none;
  p {
    font-size: 14px;
    color: #666666;
    line-height: 20px;
    margin-top: 12px;
    &:first-child {
      margin-top: 0;
    }
    .TitLabel {
      padding-right: 10px;
    }
    
  }
}
.paymentType {
  padding: 0 20px 36px 20px;
  border: 1px solid #f0f0f0;
  border-top: none;
  .paymentLine {
    font-size: 14px;
    color: #666666;
    line-height: 30px;
    margin-top: 12px;
    &:first-child {
      margin-top: 0;
    }
    .TitLabel {
      padding-right: 10px;
    }
    .payBox{
        padding:6px 18px;
        width:66px;
        height:20px;
        display: inline-block;
         text-align: center;
        line-height:20px;
        border: 2px solid #FFD303;
        background: url("../assets/checkedRed.png") no-repeat right bottom;
        background-size: 26px 26px;
    }
  }
}
</style>
